import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { useUploadFiles } from "../FileQueries/uploadFileQueries";
import "./styles/upload-files-modal.css";

const UploadFilesModal = ({
  open,
  setOpen,
  projectId,
  selectedFile,
  setSelectedFile,
  setOpenSettings,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { clientId, queryClient } = useContext(DashboardContext);
  const uploadFiles = useUploadFiles(projectId, queryClient);

  const handleUploadFile = async (files) => {
    try {
      const category = selectedFile.name;

      console.log("Category: ", category);

      await uploadFiles.mutateAsync({
        clientId,
        projectId,
        idProp: category,
        files: files,
      });

      enqueueSnackbar(
        `${files.length > 1 ? "All Files" : "File"} uploaded successfully`,
        {
          variant: "success",
        },
      );
    } catch (error) {
      console.log("UPLOAD FILE ERROR: ", error);
      enqueueSnackbar(
        `${files.length > 1 ? "All Files" : "File"} failed to upload`,
        {
          variant: "error",
        },
      );
    } finally {
      setSelectedFile({
        id: "",
        name: "",
        file: "",
        required: false,
      });
      setOpen(false);
      setOpenSettings(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const uploadedFiles = Array.from(e.dataTransfer.files);
    if (uploadedFiles.length === 0) return;

    enqueueSnackbar(`Uploading Files`, {
      variant: "info",
      autoHideDuration: 1500,
    });

    await handleUploadFile(uploadedFiles);
  };

  const handleFileSelect = async (e) => {
    const uploadedFiles = Array.from(e.target.files);

    enqueueSnackbar(`Uploading Files`, {
      variant: "info",
      autoHideDuration: 1500,
    });
    await handleUploadFile(uploadedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setOpenSettings(false);
        setSelectedFile({
          id: "",
          name: "",
          file: "",
          required: false,
        });
      }}
      className="upload-files-modal"
    >
      <div className="upload-missing-files">
        <Box
          className="drag-files-box"
          id="drag-files-box"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onClick={() => document.getElementById("hidden-upload-input").click()}
        >
          <UploadIcon
            className="upload-missing-icon"
            id="upload-missing-icon"
          />
          <span className="drag-drop-text" id="drag-drop-text">
            <u>Click to select</u> or Drag & Drop files here
          </span>
          <input
            id="hidden-upload-input"
            multiple
            type="file"
            hidden
            onChange={handleFileSelect}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default UploadFilesModal;
