import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import SvgIcon from "@mui/material/SvgIcon";
import React from "react";
import "./styles/project-file-structure.css";
import "./styles/project-files.css";

const glyphs = {
  acrobat: (
    <path
      d="M10.15 1.095C9.938.33 9.42-.051 8.984.005c-.528.068-1.09.382-1.314.876-.63 1.416.685 5.582.887 6.279-1.28 3.863-5.66 11.5-7.806 12.017-.045-.505.225-1.965 3.055-3.785.146-.157.315-.348.393-.472-2.392 1.168-5.492 3.044-3.628 4.448.102.079.259.146.439.213 1.426.528 3.425-1.201 5.435-5.121 2.213-.73 3.999-1.28 6.526-1.662 2.762 1.875 4.616 2.257 5.874 1.774.348-.135.898-.573 1.055-1.145-1.022 1.258-3.414.382-5.323-.82 1.763-.191 3.582-.303 4.369-.056 1 .314.965.808.954.876.079-.27.191-.708-.022-1.056-.842-1.37-4.706-.573-6.11-.427-2.212-1.336-3.74-3.717-4.358-5.436.573-2.212 1.19-3.818.742-5.413zm-.954 4.638C8.826 4.42 8.309 1.5 9.14.556c1.628.932.618 3.144.056 5.177zm3.044 6.514c-2.134.393-3.583.944-5.66 1.764.617-1.202 1.785-4.268 2.346-6.29.787 1.573 1.741 3.111 3.314 4.526z"
      transform="translate(14 9)"
      fillRule="evenodd"
    />
  ),
  document: (
    <path
      d="M12 4H0v2h12V4zM0 10h18V8H0v2zM0 0v2h18V0H0z"
      transform="translate(15 15)"
      fillRule="evenodd"
    />
  ),
  image: (
    <path
      d="M13 0L9.25 5l2.85 3.8-1.6 1.2C8.81 7.75 6 4 6 4l-6 8h22L13 0z"
      transform="translate(13 14)"
      fillRule="evenodd"
    />
  ),
  spreadsheet: (
    <path
      d="M0 8h6V5H0v3zm0 5h6v-3H0v3zM0 3h6V0H0v3zm8 5h12V5H8v3zm0 5h12v-3H8v3zM8 0v3h12V0H8z"
      transform="translate(14 14)"
      fillRule="evenodd"
    />
  ),
};

const customStyles = {
  pdf: {
    glyphColor: "rgb(240, 240, 240)",
    labelColor: "rgb(217, 54, 48)",
    foldColor: "rgba(168, 40, 35, 0.924)",
    labelTextColor: "rgb(240, 240, 240)",
    color: "rgb(217, 54, 48)",
    type: "acrobat",
  },
  doc: {
    color: "#2C5898",
    foldColor: "#254A80",
    glyphColor: "rgba(255,255,255,0.4)",
    labelColor: "#2C5898",
    labelUppercase: true,
    type: "document",
  },
  docx: {
    color: "#2C5898",
    foldColor: "#254A80",
    glyphColor: "rgba(255,255,255,0.4)",
    labelColor: "#2C5898",
    labelUppercase: true,
    type: "document",
  },
  png: {
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgba(185, 130, 42, 1)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(182, 133, 54, 0.952)",
    type: "image",
  },
  jpg: {
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgba(185, 130, 42, 1)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(182, 133, 54, 0.952)",
    type: "image",
  },
  jpeg: {
    glyphColor: "rgba(185, 130, 42, 1)",
    labelColor: "rgba(185, 130, 42, 1)",
    labelTextColor: "rgba(185, 130, 42, 1)",
    color: "rgb(230, 230, 230)",
    foldColor: "rgba(182, 133, 54, 0.952)",
    type: "image",
  },
  xls: {
    color: "#1A754C",
    foldColor: "#16613F",
    glyphColor: "rgba(255,255,255,0.4)",
    labelColor: "#1A754C",
    labelUppercase: true,
    type: "spreadsheet",
  },
  xlsx: {
    color: "#1A754C",
    foldColor: "#16613F",
    glyphColor: "rgba(255,255,255,0.4)",
    labelColor: "#1A754C",
    labelUppercase: true,
    type: "spreadsheet",
  },
  xlr: {
    color: "rgb(235, 235, 235)",
    glyphColor: "#1A754C",
    labelColor: "#1A754C",
    labelTextColor: "#1A754C",
    foldColor: "rgba(11, 85, 52, 0.941)",
    type: "spreadsheet",
  },
  csv: {
    color: "#1A754C",
    foldColor: "#16613F",
    glyphColor: "rgba(255,255,255,0.4)",
    labelColor: "#1A754C",
    labelUppercase: true,
    type: "spreadsheet",
  },
  missing_unknown: {
    color: "rgb(230, 230, 230)",
    glyphColor: "rgb(38, 139, 255)",
    labelColor: "rgb(38, 139, 255)",
    labelTextColor: "rgb(38, 139, 255)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  unknown: {
    color: "rgb(235, 235, 235)",
    glyphColor: "rgba(255, 255, 255, 0)",
    labelColor: "rgb(35, 35, 35)",
    labelTextColor: "rgb(0, 0, 0)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
  required: {
    color: "rgb(235, 235, 235)",
    glyphColor: "rgb(200, 36, 36)",
    labelColor: "rgb(200, 36, 36)",
    labelTextColor: "rgb(235, 235, 235)",
    foldColor: "rgba(190, 190, 190, 0.952)",
  },
};

const ArgusFileIcon = ({
  color = "rgb(235, 235, 235)",
  extension,
  fold = true,
  foldColor = "rgba(165, 165, 165, 0.952)",
  glyphColor = "rgb(16, 114, 151)",
  gradientColor = "rgb(235, 235, 235)",
  gradientOpacity = 0.25,
  labelColor = "var(--argus-blue-dark)",
  labelTextColor = "rgb(235, 235, 235)",
  radius = "4",
  type,
  uniqueId,
}) => {
  const vb = {
    width: 40,
    height: 48,
  };

  const argusIcon = {
    width: vb.width,
    height: vb.height,
  };

  const foldHeight = {
    height: 12,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 48"
      width="100%"
      style={{
        maxWidth: "100%",
      }}
    >
      <defs>
        <clipPath id={`pageRadius${uniqueId}`}>
          <rect x="0" y="0" rx="4" width="40" height="48" />
        </clipPath>
        <clipPath id={`foldCrop${uniqueId}`}>
          <rect width="40" height="12" transform="rotate(-45, 0, 12)" />
        </clipPath>
        <linearGradient
          x1="100%"
          y1="0%"
          y2="100%"
          id={`pageGradient${uniqueId}`}
        >
          <stop
            stopColor={gradientColor}
            stopOpacity={gradientOpacity}
            offset="0%"
          />
          <stop stopColor={gradientColor} stopOpacity="0" offset="66.67%" />
        </linearGradient>
      </defs>

      <g id="file" clipPath={`url(#pageRadius${uniqueId})`}>
        {fold ? (
          <>
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={color}
            />
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        ) : (
          <>
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={color}
            />
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        )}
      </g>

      {fold && (
        <g transform={`translate(28 ${foldHeight.height}) rotate(-90)`}>
          <rect
            width={argusIcon.width}
            height={argusIcon.height}
            fill={foldColor}
            rx={radius}
            ry={radius}
            clipPath={`url(#foldCrop${uniqueId})`}
          />
        </g>
      )}

      {extension && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y={argusIcon.height - 14}
              width={argusIcon.width}
              height={14}
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>
          <g id={`labelText${uniqueId}`} transform={`translate(0, 34)`}>
            <text
              x={argusIcon.width / 2}
              y="10"
              fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              fontSize="9"
              fill={labelTextColor}
              textAnchor="middle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                pointerEvents: "none",
                textTransform:
                  extension === "docx" || extension === "doc"
                    ? "uppercase"
                    : "none",
                userSelect: "none",
              }}
            >
              {extension}
            </text>
          </g>
        </>
      )}

      {type == "admin" && (
        <g>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="5px"
            viewBox="0 0 45 40"
            height="26"
          >
            <g>
              <path
                fill="#231F20"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M19.57,5.94L5.77,33.66l17.34,0.02
        c-4.03-3.25-4.67-9.46-1.95-14.49L28.6,5.94H19.57z"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M30.56,34.06"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M22.95,20.66"
              />
              <path
                fill="none"
                stroke="#231F20"
                strokeMiterlimit="10"
                d="M38.01,21"
              />
              <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1="37.4634"
                y1="28.3434"
                x2="24.6532"
                y2="17.5794"
              >
                <stop offset="6.13%" stopColor="#066D94" />
                <stop offset="41.35%" stopColor="#5A93B4" />
                <stop offset="79.66%" stopColor="#92C5DD" />
                <stop offset="97.78%" stopColor="#ABE0F2" />
              </linearGradient>
              <path
                fill="url(#SVGID_1_)"
                d="M29.9,7.3l-7.44,13.36c-0.58,1.19-0.91,2.13-1.02,2.87c-0.3,1.58-0.18,3.11,0.25,4.62
        c0.54,1.57,1.34,2.76,2.38,3.61c1.79,1.65,3.81,2.3,6,2.3c1.45,0,2.87-0.36,4.25-1.23c0.97-0.59,1.82-1.37,2.53-2.37
        c0.76-1.02,1.26-2,1.51-3.3c0.24-1.25,0.26-2.25,0.12-3.32c-0.15-0.97-0.48-1.91-0.96-2.84L29.9,7.3z"
              />
            </g>
          </svg>
        </g>
      )}
    </svg>
  );
};

const FileTypeIcon = ({
  color = "rgb(235, 235, 235)",
  extension,
  fold = true,
  foldColor = "rgba(190, 190, 190, 0.952)",
  glyphColor,
  gradientColor = "rgb(235, 235, 235)",
  gradientOpacity = 0.25,
  labelColor,
  labelTextColor = "rgb(235, 235, 235)",
  labelUppercase = false,
  radius = 4,
  type,
  uniqueId,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 48"
      width="100%"
      style={{
        maxWidth: "100%",
      }}
    >
      <defs>
        <clipPath id={`pageRadius${uniqueId}`}>
          <rect x="0" y="0" rx={radius} ry={radius} width="42" height="48" />
        </clipPath>

        <clipPath id={`foldCrop${uniqueId}`}>
          <rect width="42" height="14" transform={`rotate(-45, 0, 14)`} />
        </clipPath>

        <linearGradient
          x1="100%"
          y1="0%"
          y2="100%"
          id={`pageGradient${uniqueId}`}
        >
          <stop
            stopColor={gradientColor}
            stopOpacity={gradientOpacity}
            offset="0%"
          />
          <stop stopColor={gradientColor} stopOpacity="0" offset="66.67%" />
        </linearGradient>
      </defs>

      <g id="file" clipPath={`url(#pageRadius${uniqueId})`}>
        {fold ? (
          <>
            <path d="M0 0 h 28 L 42 14 v 34 H 0 Z" fill={color} />
            <path
              d="M0 0 h 28 L 42 14 v 34 H 0 Z"
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        ) : (
          <>
            <rect x="0" y="0" width="42" height="48" fill={color} />
            <rect
              x="0"
              y="0"
              width="42"
              height="48"
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        )}
      </g>
      {fold && (
        <>
          <g transform={`translate(28 14) rotate(-90)`}>
            <rect
              width="42"
              height="48"
              fill={foldColor}
              rx={radius}
              ry={radius}
              clipPath={`url(#foldCrop${uniqueId})`}
            />
          </g>
        </>
      )}

      {extension && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y="-22" //Could be 28
              width="42"
              height="14"
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>

          <g id={`labelText${uniqueId}`} transform={`translate(0, 34)`}>
            <text
              x="21"
              y="10"
              fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif"
              fontSize="9"
              fill={labelTextColor}
              textAnchor="middle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                pointerEvents: "none",
                textTransform: labelUppercase ? "uppercase" : "none",
                userSelect: "none",
              }}
            >
              {extension}
            </text>
          </g>
        </>
      )}

      {type && glyphs[type] && (
        <g transform={`translate(-4 ${!extension ? 6 : 0})`} fill={glyphColor}>
          {glyphs[type]}
        </g>
      )}
    </svg>
  );
};

const BlankFileIcon = ({
  color = "rgb(235, 235, 235)",
  extension,
  fold = true,
  foldColor = "rgba(190, 190, 190, 0.952)",
  glyphColor = "transparent",
  gradientColor = "rgb(235, 235, 235)",
  gradientOpacity = 0.25,
  labelColor = "rgb(235, 235, 235)",
  labelTextColor = "rgb(206, 33, 33)",
  radius = "4",
  type,
  uniqueId,
}) => {
  const vb = {
    width: 42,
    height: 48,
  };

  const argusIcon = {
    width: vb.width,
    height: vb.height,
  };

  const foldHeight = {
    height: 14,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42 48"
      width="100%"
      style={{
        maxWidth: "100%",
      }}
    >
      <defs>
        <clipPath id={`pageRadius${uniqueId}`}>
          <rect x="0" y="0" rx="4" width="42" height="48" />
        </clipPath>
        <clipPath id={`foldCrop${uniqueId}`}>
          <rect
            width="42"
            height={foldHeight.height}
            transform={`rotate(-45, 0, ${foldHeight.height})`}
          />
        </clipPath>
        <linearGradient
          x1="100%"
          y1="0%"
          y2="100%"
          id={`pageGradient${uniqueId}`}
        >
          <stop
            stopColor={gradientColor}
            stopOpacity={gradientOpacity}
            offset="0%"
          />
          <stop stopColor={gradientColor} stopOpacity="0" offset="66.67%" />
        </linearGradient>
      </defs>

      <g id="file" clipPath={`url(#pageRadius${uniqueId})`}>
        {fold ? (
          <>
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={color}
            />
            <path
              d={`M0 0 h ${argusIcon.width - foldHeight.height} L ${
                argusIcon.width + 0
              } ${foldHeight.height} v ${
                argusIcon.height - foldHeight.height
              } H 0 Z`}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        ) : (
          <>
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={color}
            />
            <rect
              x="0"
              y="0"
              width={argusIcon.width}
              height={argusIcon.height}
              fill={`url(#pageGradient${uniqueId})`}
            />
          </>
        )}
      </g>

      {fold && (
        <g transform={`translate(28 ${foldHeight.height}) rotate(-90)`}>
          <rect
            width={argusIcon.width}
            height={argusIcon.height}
            fill={foldColor}
            rx={radius}
            ry={radius}
            clipPath={`url(#foldCrop${uniqueId})`}
          />
        </g>
      )}

      {type === "required" && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y={argusIcon.height - 14}
              width={argusIcon.width}
              height={15}
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>
          <g id={`labelText${uniqueId}`}>
            <text
              id="required-extension-text"
              className="required-extension-text"
              x={argusIcon.width / 2}
              y={argusIcon.height - 4}
              textAnchor="middle"
              textLength={"90%"}
              style={{
                color: labelTextColor,
              }}
            >
              {"Required"}
            </text>
          </g>
        </>
      )}

      {type === "unknown" && (
        <>
          <g id={`label${uniqueId}`}>
            <rect
              fill={labelColor}
              x="0"
              y={argusIcon.height - 14}
              width={argusIcon.width}
              height={14}
              clipPath={`url(#pageRadius${uniqueId})`}
            />
          </g>
          <g id={`labelText${uniqueId}`} transform={`translate(0, 34)`}>
            <text
              id="missing-extension-text"
              className="missing-extension-text"
              x={argusIcon.width / 2}
              y="10"
              textAnchor="middle"
              // textLength={"68%"}
              style={{
                color: labelTextColor,
              }}
            >
              {"No File"}
            </text>
          </g>
        </>
      )}
      {type === "required" && (
        <g>
          <SvgIcon
            className="required-file-svg"
            id="required-file-svg"
            color={glyphColor}
            htmlColor={glyphColor}
            viewBox="0 2 9 21"
            fontSize="small"
            style={{
              transform: "scale(0.75) !important",
            }}
            sx={{
              fontSize: "10px",
              fill: glyphColor,
              height: "15px",
              width: "15px",
            }}
            inheritViewBox={false}
            classes={{
              root: "required-file-svg-root",
              fontSizeSmall: "required-file-svg-fontSizeSmall",
            }}
          >
            <WarningRoundedIcon
              className="required-file-icon"
              id="required-file-icon"
              classes={{
                root: "required-file-icon-root",
                fontSizeSmall: "required-file-icon-fontSizeSmall",
              }}
              fontSize="small"
              style={{
                transform: "scale(0.75) !important",
              }}
              sx={{
                fontSize: "10px",
                fill: glyphColor,
                height: "15px",
                width: "12px",
                scale: "75% !important",
                transform: "scale(0.75) !important",
              }}
            />
          </SvgIcon>
        </g>
      )}
    </svg>
  );
};

export { ArgusFileIcon, BlankFileIcon, customStyles, FileTypeIcon };
