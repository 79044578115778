import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import RequiredFiles from "./MissingFiles";
import UploadedFiles from "./UploadedFiles";
import "./styles/project-file-structure.css";
import { setClientID, setProjectID } from "../../../../Redux/Actions/actions";

// Component to display the uploaded or required folders for a project
const FilesFolder = ({ queryType }) => {
  const {
    client,
    clientId,
    projectId,
    setHeading,
    navigate,
    isAdmin,
    queryClient,
  } = useContext(DashboardContext);

  const dispatch = useDispatch();

  // Set the heading based on the query type
  useEffect(() => {
    if (queryType === "uploaded") {
      setHeading("Uploaded Files");
    } else if (queryType === "required") {
      setHeading("Required Files");
    } else if (queryType === "all") {
      navigate(`/dashboard/files/${clientId}/${projectId}`);
    }
  }, []);

  return (
    <div className="project-files-container">
      <div className="breadcrumbs-container">
        <Breadcrumbs
          separator="/"
          aria-label="breadcrumb"
          classes={{
            root: "breadcrumbs-root",
            separator: "breadcrumbs-separator",
          }}
        >
          {isAdmin && (
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              onClick={() => {
                dispatch(setClientID(null));
                dispatch(setProjectID(null));
                navigate("/dashboard/files");
              }}
              onMouseOver={async (e) => {
                e.preventDefault();
                await queryClient.prefetchQuery({
                  queryKey: ["all-clients-structure"],
                  queryFn: async () => {
                    const res = await axios.get(`/api/all-clients-structure/`, {
                      withCredentials: true,
                    });
                    return res.data;
                  },
                  staleTime: 120000,
                });
              }}
              startIcon={<HomeIcon id="root-folder-icon" />}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                Clients Folder
              </Typography>
            </Button>
          )}
          {!isAdmin && (
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              onMouseOver={async (e) => {
                e.preventDefault();
                await queryClient.prefetchQuery({
                  queryKey: ["client-structure", clientId],
                  queryFn: async () => {
                    const res = await axios.get(
                      `/api/client-structure/${clientId}/`,
                      {
                        withCredentials: true,
                      },
                    );
                    return res.data;
                  },
                  staleTime: 120000,
                });
              }}
              onClick={() => {
                dispatch(setProjectID(null));
                navigate(`/dashboard/files/${clientId}`);
              }}
              startIcon={<HomeIcon id="root-folder-icon" />}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                {`${client.shortName} Folder`}
              </Typography>
            </Button>
          )}
          {isAdmin && (
            <Button
              className="breadcrumbs-btn root-link-btn"
              variant="text"
              onMouseOver={async (e) => {
                e.preventDefault();
                await queryClient.prefetchQuery({
                  queryKey: ["client-structure", clientId],
                  queryFn: async () => {
                    const res = await axios.get(
                      `/api/client-structure/${clientId}/`,
                      {
                        withCredentials: true,
                      },
                    );
                    return res.data;
                  },
                  staleTime: 120000,
                });
              }}
              onClick={() => {
                dispatch(setProjectID(null));
                navigate(`/dashboard/files/${clientId}`);
              }}
            >
              <Typography
                variant="body1"
                className="breadcrumbs-value root-text"
              >
                Client Folder
              </Typography>
            </Button>
          )}
          <Button
            className="breadcrumbs-btn root-link-btn"
            variant="text"
            onMouseOver={async (e) => {
              e.preventDefault();
              const userType = isAdmin ? "admin" : "client";
              await queryClient.prefetchQuery({
                queryKey: ["all-project-structure", projectId, userType],
                queryFn: async () => {
                  const res = await axios.get(
                    `/api/all-project-structure/${projectId}/${userType}/`,
                    {
                      withCredentials: true,
                    },
                  );
                  return res.data;
                },
                staleTime: 120000,
              });
            }}
            onClick={() =>
              navigate(`/dashboard/files/${clientId}/${projectId}`)
            }
          >
            <Typography variant="body1" className="breadcrumbs-value root-text">
              Project Folder
            </Typography>
          </Button>
          <Typography
            variant="body1"
            className="breadcrumbs-value current-text"
          >
            {queryType === "uploaded"
              ? "Uploaded Files"
              : queryType === "required"
              ? "Required Files"
              : "Form Files"}
          </Typography>
        </Breadcrumbs>
      </div>
      {queryType === "required" && !isAdmin && (
        <p id="missing-files-description">
          {
            "This section contains all the files from the services questionnaire that have been marked required by an Argus team memeber and have not been uploaded. Click to the file icon to upload the required file."
          }
        </p>
      )}
      {queryType === "required" && isAdmin && (
        <p id="missing-files-description">
          {
            "Click on a singular file to upload a file or click the 'Require Files' button to select required files."
          }
        </p>
      )}
      {queryType === "uploaded" && (
        <p id="missing-files-description">
          {
            "This section contains all the files that have been uploaded for this project. Click on the file icon to view/remove the file or click the green button to upload new file(s)."
          }
        </p>
      )}
      {queryType === "uploaded" && (
        <UploadedFiles
          projectId={projectId}
          queryClient={queryClient}
          userType={isAdmin ? "admin" : "client"}
        />
      )}
      {queryType === "required" && (
        <RequiredFiles
          projectId={projectId}
          isAdmin={isAdmin}
          queryClient={queryClient}
          userType={isAdmin ? "admin" : "client"}
        />
      )}
    </div>
  );
};

export default FilesFolder;
